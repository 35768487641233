import FileSaver from "file-saver";
import moment from "moment";
import API from "./../api";

export const getAllConfig = async (params: any) => {
  try {
    const response = await API.get("admin/config/lockingTime", params).then((res) => res.data);
    if (response.success) {
      const listData = [...response.data];
      listData.forEach((item: { key: any; _id: any }) => {
        item.key = item._id;
      });
      return { data: listData, total: response.totalItem };
    }
  } catch (error) {
    throw error;
  }
};

export const createLockingTime = async (params: any) => {
  try {
    const response = await API.post(`admin/config/lockingTime`, params).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateLockingTime = async (id: string, data: any) => {
  try {
    const response = await API.put(`admin/config/lockingTime/${id}`, data).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteLockingTime = async (id: string) => {
  try {
    const response = await API.delete(`admin/config/lockingTime/${id}`).then(
      (res) => res.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
