import "./configScreen.css";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import layout from "antd/es/layout";
import {
  getAllConfig,
  createLockingTime,
  updateLockingTime,
  deleteLockingTime
} from "../../shared/api/AdminConfig";
import moment from "moment";
import MomentDatePicker from "../../Components/MomentDatePicker";
import {rule_number, rule_require} from "../../shared/validateInput";
import {LOCKING_TYPE, LOCKING_TYPE_DISPLAY} from "../../shared/define"

export const ConfigList = () => {
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);

  const [lockDate, setLockDate] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any>();

  const [configList, setConfigList] = useState({
    list: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  console.log('form.getFieldsValue()', form.getFieldsValue())
  const fetchDataConfig = async () => {
    setConfigList({ ...configList, loading: true });
    const response: any = await getAllConfig({
      params: {
        current: configList.pagination.current,
      },
    });
    setConfigList({
      ...configList,
      list: response?.data,
      loading: false,
      pagination: { ...configList.pagination, total: response.totalItem },
    });
  };

  useEffect(() => {
    fetchDataConfig();
  }, [configList.pagination.current]);

  const columns = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Mô tả",
      dataIndex: ["name"],
      width: 400
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (value: any) => {
        switch (value) {
          case 'CREATE_NEW_ORDER':
            return 'Khóa tạo đơn mới';
            case 'FINISH_ORDER_MANUAL':
              return 'Hoàn thành đơn bằng tay';
          default:
                return '';
        }
      },
    },
    {
      title: "Từ",
      dataIndex: ["from"],
      render: (value: string) => {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
      }
    },
    {
      title: "Đến",
      dataIndex: ["to"],
      render: (value: string) => {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
      }
    },
    {
      title: "Tác vụ",
      width: 100,
      render: (record: any) => {
        return (
          <Space size="middle">
            <Tooltip title="Sửa">
              <Button
                type="text"
                onClick={() => {
                  const data = {
                    _id: record._id,
                    name: record.name,
                    type: record.type,
                    from: moment(record.from),
                    to: moment(record.to),
                  };
                  setOpen(true);
                  setSelectedItem(data);

                  form.setFieldsValue(data);
                }}
              >
                <EditOutlined style={{ fontSize: 16 }} />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Bạn có muốn xóa?"
              onConfirm={async () => {
                await deleteLockingTime(record?._id);
                await fetchDataConfig();
              }}
              okText="Có"
              cancelText="Hủy"
            >
              <Button type="text">
                <DeleteOutlined
                  style={{ fontSize: 16, color: "#FF4E4F", cursor: "pointer" }}
                />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onSubmitForm = async () => {
    console.log('form.', form.getFieldsValue())
    if (selectedItem) {
      // Update
      const response = await updateLockingTime(selectedItem._id, { ...form.getFieldsValue() });
      if (response.success) {
        await fetchDataConfig();
        setOpen(false);
      }
    } else {
      // Create
      const response = await createLockingTime({ ...form.getFieldsValue() });
      if (response.success) {
        await fetchDataConfig();
        setOpen(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedItem(undefined);
    form.resetFields();
  };

  return (
    <>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý cấu hình</Title>
        <Space size={6}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            type="primary"
            size="small"
            icon={<PlusOutlined />}
          >
            Tạo mới
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        id="config"
        scroll={{ x: "max-content" }}
        rowKey="_id"
        loading={configList.loading}
        columns={columns}
        pagination={
          configList.pagination.total > configList.pagination.pageSize &&
          configList.pagination
        }
        dataSource={configList.list}
        onChange={(value: any) =>
          setConfigList({
            ...configList,
            pagination: { ...configList.pagination, current: value.current },
          })
        }
      />
      <Modal
        open={open}
        title={selectedItem ? "Cập nhật" : "Tạo mới"}
        onCancel={handleCloseModal}
        onOk={() => {
          form.submit();
        }}
      >
        <Form {...layout} form={form} onFinish={onSubmitForm} layout="vertical">
          <Form.Item name="_id" label="id" hidden>
            <Input type="text" disabled hidden />
          </Form.Item>
          <Row style={{ padding: 10 }}>
            <Col span={24}>
              <Form.Item name={["name"]} label="Mô tả">
                <Input
                  style={{ width: "100%" }}
                  value={"Khóa tính năng"}
                  defaultValue={"Khóa tính năng"}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="type" label="Loại">
                <Select
                  // showSearch
                  style={{ width: 200 }}
                  placeholder="Loại"
                  // fieldNames={{ value: "name", label: "Nội dung" }}
                  options={LOCKING_TYPE_DISPLAY}
                  // defaultValue={LOCKING_TYPE_DISPLAY[0]}
                  // value={LOCKING_TYPE_DISPLAY[0]}
                  onSelect={(_value, option: any) => {
                    form.setFieldValue("type", option?.value);
                  }}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="from" label="Ngày bắt đầu" >
                <MomentDatePicker
                  style={{ width: "100%" }}
                  format={date => date.startOf('day').format("DD/MM/YYYY HH:mm:ss")}
                  // value={moment().add(-1, 'month')}
                  // defaultValue={moment().add(-1, 'month')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="to" label="Ngày kết thúc" >
                <MomentDatePicker
                  style={{ width: "100%" }}
                  format={date => date.endOf('day').format("DD/MM/YYYY HH:mm:ss")}
                  // value={moment()}
                  // defaultValue={moment()}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/*<Modal*/}
      {/*  width={300}*/}
      {/*  destroyOnClose={true}*/}
      {/*  closable={true}*/}
      {/*  title="Chọn ngày chốt dầu"*/}
      {/*  open={openLockModal}*/}
      {/*  onCancel={() => setOpenLockModal(false)}*/}
      {/*  onOk={handleLockRefueling}*/}
      {/*  okText={*/}
      {/*    <>*/}
      {/*      <VerticalAlignBottomOutlined />*/}
      {/*      Xác nhận*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  cancelText="Hủy"*/}
      {/*  footer={*/}
      {/*    <Space>*/}
      {/*      <Button onClick={() => setOpenLockModal(false)}>Huỷ</Button>*/}
      {/*      <Popconfirm*/}
      {/*        title={`Bạn có chắc muốn chốt dầu vào ngày ${lockDate}?`}*/}
      {/*        onConfirm={handleLockRefueling}*/}
      {/*      >*/}
      {/*        <Button type="primary" disabled={!lockDate}>*/}
      {/*          Xác nhận*/}
      {/*        </Button>*/}
      {/*      </Popconfirm>*/}
      {/*    </Space>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <MomentDatePicker*/}
      {/*    format={"YYYY-MM-DD"}*/}
      {/*    style={{ width: "100%" }}*/}
      {/*    onChange={(date) => setLockDate(moment(date).format("YYYY-MM-DD"))}*/}
      {/*  />*/}
      {/*</Modal>*/}
    </>
  );
};
